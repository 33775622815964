<template>
  <div class="page-container cost-centers-page">
    <portal to="page-name">Gerenciamento de centro de custo</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Centro de custo</h1>
      </div>
      <div class="card-body">
        <div class="loading loading-lg mt-2" v-if="loading" />
        <template v-else>
          <template v-if="costCenters.length === 0">
            <div class="empty">
              <div class="empty-icon mt-2">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Centro de custo</p>
              <p class="empty-subtitle">
                Você não possui nenhum centro de custo cadastrado.
              </p>
              <div class="action">
                <button class="btn btn-primary btn-icon btn-icon-left mr-2"
                        @click="open">
                  <fa-icon :icon="['fal', 'plus']"/>Novo centro de custo
                </button>
              </div>
            </div>
          </template>
          <div class="cost-center" v-else>
            <div class="columns mb-2">
              <div class="column">
                <div class="h5">Estrutura</div>
              </div>
              <div class="column centered text-right">
                <button class="btn btn-primary btn-icon btn-icon-left"
                        @click="open">
                  <fa-icon :icon="['fal', 'plus']"/>Novo centro de custo
                </button>
              </div>
            </div>
            <div class="empty" v-if="costCenters.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-subtitle">
                Nenhum centro de custo cadastrado
              </p>
            </div>
            <div v-for="first in costCenters" :key="first.id" v-else>
              <item
                :data="first"
                :new-cost-center="true"
                @open="open"
                @edit="edit"
                @remove="remove"
              />
              <div v-if="first.items && first.items.length > 0">
                <div v-for="secondary in first.items" :key="secondary.id">
                  <item
                    class-style="sub-item secondary"
                    :data="secondary"
                    :new-cost-center="true"
                    @open="open"
                    @edit="edit"
                    @remove="remove"
                  />
                  <div v-if="secondary.items && secondary.items.length > 0">
                    <div v-for="third in secondary.items" :key="third.id">
                      <item
                        class-style="sub-item third"
                        :data="third"
                        @open="open"
                        @edit="edit"
                        @remove="remove"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <modal-form
      v-if="modal.show"
      :id="modal.id"
      :show="modal.show"
      :parent-id="modal.parentId"
      :cost-centers="costCenters"
      @close="close"
    />
  </div>
</template>

<script>
import item from './Item.vue';
import modalForm from './Modal.vue';

export default {
  components: {
    item,
    modalForm,
  },
  data() {
    return {
      loading: false,
      deleting: false,
      costCenters: [],
      modal: {
        id: null,
        parentId: '',
        show: false,
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;

      const params = {
        limit: 0,
      };

      this.costCenters = [];
      this.$http.get('/cost-centers', { params })
        .then(({ data }) => {
          data.items.forEach((cost) => {
            if (cost.parent) {
              this.costCenters.forEach((first) => {
                if (first.id === cost.parent.id) {
                  if (!first.items) {
                    first.items = [];
                  }
                  first.items.push(cost);
                } else if (first.items) {
                  first.items.forEach((secondary) => {
                    if (secondary.id === cost.parent.id) {
                      if (!secondary.items) {
                        secondary.items = [];
                      }
                      secondary.items.push(cost);
                    }
                  });
                }
              });
            } else {
              this.costCenters.push(cost);
            }
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    open(args) {
      this.modal.id = null;
      this.modal.parentId = args.id || '';
      this.modal.show = true;
    },
    edit(args) {
      this.modal.id = args.id;
      this.modal.parent = '';
      this.modal.show = true;
    },
    close() {
      this.load();
      this.modal.show = false;
    },
    remove(args) {
      if (this.deleting) {
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente remover este centro de custo?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              return this.$http.delete(`/cost-centers/${args.id}`)
                .catch(() => {})
                .finally(() => {
                  this.load();
                  this.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .cost-centers-page {
    .cost-center {
      margin: 1rem 0 2rem 0;
      .cost-center-item {
        align-items: center;
        border-top: $border-width solid $border-color;
        display: flex;
        font-size: $font-size-lg;
        height: 2.4rem;
        justify-content: space-between;
        &:hover {
          background-color: $gray-color-ultra-light;
        }
      }
      .sub-item {
        border-top: $border-width solid $border-color;
        font-size: $font-size;
        &.secondary {
          padding-left: $layout-spacing-xl;
        }
        &.third {
          padding-left: $layout-spacing-xl * 2;
        }
      }
    }
    .empty {
      .action {
        margin: $layout-spacing-lg 0;
      }
    }
  }
</style>
